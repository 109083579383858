import i18n from '@/plugins/i18n';

export const TABLE_HEADERS = [
  {
    text: 'Sposób wysyłki',
    value: 'configurationName',
  },
  {
    text: 'Integracja z usługodawcą',
    value: 'integration',
  },
  {
    text: 'Kurier realizujący',
    value: 'courier',
  },
  {
    value: 'externalLP',
    width: 150,
  },
  {
    value: 'events',
    width: 40,
  },
];

export const ROW_ACTIONS = [{
  icon: {
    name: 'mdi-pencil-outline',
  },
  text: i18n.t('actions.edit'),
  value: 'edit',
}, {
  icon: {
    name: 'mdi-file-document-multiple-outline',
  },
  divider: true,
  text: i18n.t('actions.copy'),
  value: 'copy',
}, {
  icon: {
    name: 'mdi-delete-outline',
    color: 'danger',
  },
  text: i18n.t('actions.delete'),
  textColor: 'danger',
  value: 'delete',
}];
